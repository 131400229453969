import React from "react";
import { Link, useLocation } from "react-router-dom";
import "./SiteHeader.css";

export const SiteHeader: React.FC = () => {
  const currentPathname = useLocation().pathname;
  const getActivePathClass = (pathname: string) => {
    return currentPathname === pathname ? "active" : null;
  };

  const homePath = "/";
  const accoladesPath = "/accolades";
  const contactPath = "/contact";

  return (
    <div className="site-header">
      <h2 className="site-title">
        <Link to={homePath}>Andrew Marks Pipemaker</Link>
      </h2>
      <div className="navbar">
        <Link
          className={`navbar-link home ${getActivePathClass(homePath)}`}
          to={homePath}
        >
          Home
        </Link>
        <Link
          className={`navbar-link resume ${getActivePathClass(accoladesPath)}`}
          to={accoladesPath}
        >
          Accolades
        </Link>
        <Link
          className={`navbar-link resume ${getActivePathClass(contactPath)}`}
          to={contactPath}
        >
          Contact
        </Link>
      </div>
    </div>
  );
};
