import React from "react";
import { SiteHeader } from "./SiteHeader";
import "./ContactPage.css";
import sketch from "../assets/contact-sketch.jpg";

export const ContactPage: React.FC = () => (
  <div className="page-container">
    <SiteHeader />
    <div className="narrow-container">
      <h2 className="page-title">Contact</h2>
      <div className="double-spaced">
        <p>
          Dear Friend of the Briar,
          <br />
          Thank you for your visit. I hope you have enjoyed some of the handmade
          briar pipes that I have created over the better part of a century.
        </p>
        <p>Feel free to inquire regarding availability and pricing.</p>
        <br />
        <p>
          Andrew Marks, Pipemaker
          <br /> Cornwall, Vermont
          <br />
          andrewmakermarks@gmail.com
          <br />1 (802) 462-2112
        </p>
      </div>
    </div>
    <div className="safe-container">
      <img
        src={sketch}
        alt="Drawing of Andrew Marks, Pipemaker"
        className="contact-image"
      />
    </div>
  </div>
);
