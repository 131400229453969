import { HashRouter, Routes, Route } from "react-router-dom";
import { HomePage } from "./HomePage";
import { AccoladesPage } from "./AccoladesPage";
import { ContactPage } from "./ContactPage";

function App() {
  return (
    <HashRouter>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="accolades" element={<AccoladesPage />} />
        <Route path="contact" element={<ContactPage />} />
      </Routes>
    </HashRouter>
  );
}

export default App;
