import React from "react";
import { SiteHeader } from "./SiteHeader";
import "./AccoladesPage.css";

interface AccoladeProps {
  message: string;
  from: string;
}

const accolades: AccoladeProps[] = [
  {
    message: `Dear Andrew,
    A note to express my deep enjoyment of about a dozen of your pipes that I own and smoke. Their dates of carving span the 1970’s through 2018. The flavor of the briar is uniformly marvelous. Your designs are uniquely “Marks” and I love the sleek lines of the bowls flowing effortlessly into the shanks — or is it vice-versa? Don’t ever stop.`,
    from: `Todd L. Platek
    November 2018
    Berkeley Heights, New Jersey`,
  },
  {
    message: `Andrew, hello!
    Thank you for taking the time to meet with me and allowing me in your home. Thank you for sharing your art, conversation and coffee too.
    I did have occasion to smoke the new pipe about four times so far. From the first smoke it has performed great. The pipe stays cool and dry and smokes all the way down without constant relights. It is an amazing pipe!`,
    from: `David Weinberg
    August 2018
    St. Louis, Missouri`,
  },
  {
    message: `Yes! Got it yesterday and I am both pleased and impressed! I have to get over staring at it so I can smoke it. The shape is very nice and unique. I am looking forward to my next pipe.
    Take care, stay warm and be safe.`,
    from: `James Masud
    January 2018
    Monrovia, California`,
  },
  {
    message: `Hi Andrew,
    It would be remiss of me if I didn’t take a moment to thank you for creating such an exceptional smoking pipe. It is truly a joy to smoke with beauty to admire. From the first light this briar delivers all the flavor that I’m accustomed to from a very familiar tobacco I smoke daily. It is very sweet, remains dry and draws with ease. Appreciate that you leave the bowl interior a little rough as I believe this aids in “breathability” and cake development. Oh, love the button.`,
    from: `Mark Levitas
    December 2016
    Goshen, New York`,
  },
  {
    message: `Hi Andrew,
    Enjoying your pipe beyond words–maybe delicious will suffice.`,
    from: `Mark Levitas
    December 2016
    Goshen, New York`,
  },
  {
    message: `Just a note,
    I don’t know if I ever told you this but the pipe I purchased from you is the jewel of my collection. It resides in a place of honor on my nightstand where I fondle it every so often. I’ve never smoked it and don’t plan to–I prize it too much. What a gorgeous straight grain!`,
    from: `Christopher Landry
    December 2016
    Dresher, Pennsylvania`,
  },
  {
    message: `Andrew,
    I can’t believe it’s already been a year that I have had this beautiful pipe. It is still smoking beautifully, as are the other two I added to my collection. They are the envy of my pipe club, and have made quite an impression on people here at the Chicago Pipe Show this weekend.
    Know that your work and passion are admired by many, from all walks of life. And thank you, once again!`,
    from: `Nathan Mattia
    May 2016
    St. Louis, Missouri`,
  },
  {
    message: `Andrew – My pipe arrived today in perfect condition. It is beautiful and a true work of art. Many thanks to you and your skills. It is just what I had in mind.`,
    from: `Louis Borjab, M.D.
    February 2016
    Carmel, Indiana`,
  },
  {
    message: `Dear Andrew,
    The pipe arrived safe and sound and is quickly becoming a new favorite. The bowl size is perfect and it hangs so lightly. I am smoking it now, as I happens. I feel lucky to have it.
    Slan agus beannacht i 2016!
    (Irish: Health and blessings in 2016!)`,
    from: `Wes Hamrick
    January 2016
    Galway, Ireland`,
  },
  {
    message: `Mr. Marks,
    I received your wonderful pipe on Thursday. I now have three of your pipes and they are the standouts in my collection. Aside from the superb smoking qualities and outstanding grains, the originality of design gives your pipes a certain flair, if you will, which makes your pipes the best I have seen.`,
    from: `Paul Dunn
    August 2014
    Brighton, Massachusetts`,
  },
  {
    message: `Mr. Marks,
    The pipe is an absolutely stunning piece, with and easy draw and a magnificent grain. Your pipe shows craftsmanship of the highest caliber and is most assuredly a work of art.`,
    from: `Paul Dunn
    July 2014
    Brighton, Massachusetts`,
  },
  {
    message: `Hi Andrew,
    Pipe arrived. Exceeds my expectations, fits my hand nicely and smokes well too.`,
    from: `Jon Contrastano
    May 2014
    Sarasota, Florida`,
  },
  {
    message: `Dear Andrew,
    The pipe arrived sound and safe today. It’s stunningly beautiful. I love the color and the shape is perfect. I smile every time I look at it. In my hand, it just feels right.`,
    from: `Wes Hamrick, PhD
    November 2013
    Notre Dame, Indiana`,
  },
  {
    message: `Dear Andrew,
    I just wanted to write and say what a wonderful and delightful visit my wife and I had at your studio and home. The pipe I picked out to add to my collection is one of the most beautiful, awesome, and sweetest pipes it has been my pleasure to have ever seen, and you have many such beauties. I’d like to put it in a glass case and keep on the mantle in the living room – a work of art!`,
    from: `Christopher Landry
    October 2013
    Dresher, Pennsylvania`,
  },
  {
    message: `Andrew,
    The great pipe is absolutely beautiful, exquisite workmanship. Perfect overall length and very importantly the weight is dead on. The quality of the briar is awesome.`,
    from: `Pete Winkler
    September 2013
    Grosse Pte. Park, Michagan`,
  },
  {
    message: `Andrew,
    The pipe is a beauty! Lovely grain that you’ve brought out well thanks to your craftsmanship. Beautifully and carefully made. Just receiving it and admiring it makes my day.`,
    from: `Robert Minnich
    July 2013
    Lancaster Pennsylvania`,
  },
  {
    message: `Andrew,
    I LOVE the new pipe (It just arrived). The shape is ethereal– The shank has the feeling of flowing water.`,
    from: `Robert Shadur
    June 2013`,
  },
  {
    message: `Andrew,
    The Little Bear pipe arrived and, Holy Cow, it is magnificent! What an extraordinary piece. Mike will so love it. Thanks, Andrew, you make gift giving extra special and such fun!`,
    from: `Susan M. De Felice
    Somers New York
    June 2013`,
  },
  {
    message: `Andrew,
    I just had the most sublime smoke from that big pipe. I think the jury is in: I like it.`,
    from: `Rod Howell
    Yarmouth Maine
    June 2013`,
  },
  {
    message: `Dear Andrew,
    I did receive my horn, and it is excellent. Just what i wanted, it is perfect. Your work is perfect. You are a master pipemaker.`,
    from: `Robert Corrado
    Athens, New York
    May 2013`,
  },
  {
    message: `Andrew,
    Love it, love it, love it!!`,
    from: `Robert Hoppes
    Freehold, New Jersey
    March 2013`,
  },
  {
    message: `Andrew is among the most esteemed pipemakers in the world. We collaborated upon a pipe to my specifications, and it smokes better than a Joura or Dunhill. He remains my go-to friend for all pipe purchases.`,
    from: `Keith Gerard
    Chicago, Illinois
    March 2013`,
  },
  {
    message: `Your pipes are incredible. They need no break-in and smoke beautifully. The pipes I have from you have quickly become two of my favorites. They are both beautiful to look at, feel great in my hand, and smoke incredibly well. I appreciate them all the more after visiting your studio and seeing the great care and thoughtfulness with which you nurture each of your pipes from out of the burl.`,
    from: `Tony Fan
    Boston, Massachusetts
    March 2013`,
  },
  {
    message: `Andrew,
    Your pipes enjoy an esteemed reputation… and I have collected many. They’re exceptional smokers. You’re in the enviable position of
    creating personal fixtures that provide hundreds of hours of meditative enjoyment to countless folks around the world.`,
    from: `Keith Gerard
    Chicago, Illinois
    February 2013`,
  },
  {
    message: `Hi Andrew,
    The much anticipated egg pipe came in the mail yesterday. It is a real beauty and I love it. I smoked it last night and like the other two it smoked like a dream.`,
    from: `Robert Hoppes
    Freehold, New Jersey
    December 2012`,
  },
  {
    message: `Hi Andrew,
    It arrived today. My words can’t express how happy I am with the pipe in terms of shape, size, look and feel, this is my baby. The pipe feels sooo at home in my hands and in my mouth. Difficult to express. I adore the design and I adore what you do and I am so happy you do it.`,
    from: `David Migdal
    Palo Alto, California
    November, 2011`,
  },
  {
    message: `Hi Andrew,
    I want to let you know that I absolutely love the pipes you have made for me. They smoke beautifuly, feel good in my mouth and are gorgeous to behold! Thanks again.`,
    from: `Scott Anderson
    Soquel, Calafornia
    October, 2011`,
  },
  {
    message: `Andrew,
    I just lit up your pipe and it smokes absolutely beautifully from, literally, the first puff. I am very pleased with the pipe and have placed it in the very handy part of my rack.`,
    from: `Marty Pulvers
    San Francisco, California
    April, 2011`,
  },
  {
    message: `Andrew,
    I can truly testify that an Andrew Marks pipe is broken in from the first bowl. It smokes perfectly. I can’t imagine that a pipe could possibly smoke better. You, Andrew Marks are a marvel of a pipe maker.
    This is truly the greatest pipe I have ever Smoked.`,
    from: `Bob Gilbert
    Denton, Texas
    July, 2008`,
  },
  {
    message: `Andrew,
    Came across your pipes on Google and fell in love with them. I live on Long Island, New York and would love some day to visit Vermont. LOVE ALL YOUR PIPES. There is something relaxed about them. They’re not overstated. I can see you put your life into these pipes, and it shows.
    Thanks.`,
    from: `Martin Carlino
    Patchogue, New York
    May, 2008`,
  },
  {
    message: `Andrew,
    Received your work of art yesterday, and absolutely love the pipe. The grain and feel and balance are perfect. I’ve bought a lot of pipes in my life, and I must say, yours are the best. The first time I used the
    pipe it felt like I had been smoking it for years. I have never experienced this before. You are a true master. Thanks.`,
    from: `Martin Carlino
    Patchogue, New York
    June, 2008`,
  },
  {
    message: `Dear Andrew;
    It is amazing how such a smaller size pipe can smoke so cool and look so beautiful to boot. I will be a repeat
    customer.
    Best regards, Mel`,
    from: `Melvin T. Mueller
    Nipomo, California
    May, 2008`,
  },
  {
    message: `Andrew,
    The pipe arrived yesterday. It’s a real beauty and the first smoke was excellent! I love the design, the
    size, and the light weight. Thank you so much for sending it to me. Your generosity and commitment to your work is very much appreciated.`,
    from: `Michael Williams
    Rock Hill, South Carolina
    May, 2008`,
  },
  {
    message: `Dear Andrew,
    I received my pipe yesterday. Had my first smoke in it last night. It was wonderful. I chose a G.L. Pease red virginia mix called Embarcadero. It’s everything you said it would be. Such a wonderful feel in the hand. It’s a work of art. I’m saving up for another one already. Best personal regards.`,
    from: `Len McCaffery
    Baton Rouge, Louisiana
    May, 2008`,
  },
  {
    message: `Dear Andrew,
    The Volcano is beautiful in every way– form, grace, balance…and of course function. I’ll be proud to have it. A lovely way to launch 2008! You are a fine artisan and artist in briar. With appreciation, and best wishes in the new year,`,
    from: `John Paine
    Franklin, Tennessee
    January, 2008`,
  },
  {
    message: `Andrew-
    Thank you so much for the pipe. I love everything about it: stem, shape and the beautiful briar. The shape is true Marksmanship. Love it. It’s special that you made it with me in mind and spirit and subsequently smokes like a dream. The wood is fantastic. Concerning the balance, it remained in my mouth unlit as I putzed about my workshop. Why didn’t I light it? It was so perfectly balanced in my lips I literally forgot about it in my mouth. The draw is an extension of my breath itself.`,
    from: `Thomas Martin
    Beverly, Massachusetts
    December, 2007`,
  },
  {
    message: `Andrew,
    Thanks so much for sharing your morning with me. I so enjoyed looking at your work. Having purchased a Marks Pipe- some 30 years ago- one that I still smoke daily and always turn to when I’m in the mood for its consistent, sweet, crisp smoke, I can certainly say my admiration for your work has only strengthened. Obviously, it was time for a new pipe! Looking through your ‘suitcase’ of wonderful pipes, I think I’ve found another great pipe,
    and hopefully the next 30 years with this pipe will be as enjoyable as the previous 30! The new pipe, from the start, has smoked beautifully. ‘Breaking in’ one of your pipes is a bit of a misnomer- the pipe has smoked perfectly from the first bowl full. The grain is stunning, and the pipe sits in my hand like it was carved for it. Thanks again for my second Marks pipe, and I look forward to seeing you again, in June, for my third pipe!`,
    from: `Jim Clark
    Middlebury, Vermont
    April, 2007`,
  },
  {
    message: `Marks’ Pipes
    smoke like mellow, broken-in briar friends from the initial smoke. It’s truly an amazing experience. Be warned. After an encounter with an Andrew Marks Pipe, the discerning smoker will find it difficult to resist seeking out additional Andrew Marks Pipes. They are that good.`,
    from: `Pipes & Tobaccos Magazine
    ‘For the Love of Briar’
    by Bruce Harris.
    Spring, 2007`,
  },
  {
    message: `Hi Andrew, pipe #31 arrived safely today. It’s nicer than the website photos portend. And, if it smokes anything like #5, it will be a gem. #5 smokes beautifully. I had the perfect smoking experience. It’s hard to describe, but sometimes you just get that perfect, deliciously tasting smoking experience. Very rare with a first bowl. Thanks!`,
    from: `Bruce Harris
    Scotch Plains, New Jersey
    2006`,
  },
  {
    message: `In 2005, Andrew Marks was elected into the world’s most exclusive society for pipe aficionados, the Pipe Smoker’s Hall of Fame.
    Founded in 1969 by Colonel L. Edward Lawrence to honor those of “outstanding accomplishments and a devotion to pipes,” the Pipe Smoker’s Hall of Fame displays the actual pipes owned and smoked by each of its’ (just over one hundred) elected members.
    Among the distinguished membership whose pipes now rest in the Hall of Fame are Albert Einstein, Abraham Lincoln, Anwar Sadat, Gerald R. Ford, Christopher Morley, F. Scott Fitzgerald, Ernest Hemingway, William Faulkner, Basil Rathbone, Edward G. Robinson, Charlton Heston, General Douglas MacArthur, General George Patton, Bing Crosby, Norman Rockwell, Babe Ruth, Pablo Cassals, Walter Cronkite, Admiral Richard Byrd, Buffalo Bill Cody, and Sitting Bull.`,
    from: `Pipe Smoker’s Hall of Fame
    2005`,
  },
  {
    message: `Dear Mr. Marks,
    Way back in 1980 I met you and purchased one of your hand made pipes. What a fortunate event for me! This little freehand, dated 1980, soon became my favorite. It has given me untold pleasure over the years and continues to delight each time I smoke it. Thank you again for the love and the care you put into my pipe.`,
    from: `Martin Shapiro
    Apache Junction, Arizona
    2004`,
  },
  {
    message: `Andrew,
    Up in our Adirondack camp- your gift pipe draws so easily. I hope to enjoy it much in the future!
    My best, Tom`,
    from: `Tom Dunn
    College Point, New York
    2004`,
  },
  {
    message: `Andrew, the four pipes I purchased from you years ago are still by far the best smokers that I ever had, including my own!
    Best Regards,`,
    from: `Al Baier, Pipe Maker
    Manchester, Vermont
    1996`,
  },
  {
    message: `Dear Mr. Marks,
    I have traveled and met many of the world’s greatest pipe men, and have seen the most beautiful pipes in the world, but for pure excellence in craftsmanship and for a truly great pipe that is as much a work of art worthy of the title Art, one need not trek around the world, one need only visit your little studio. I always take the greatest pleasure in sending smokers to your studio for I know they will be rewarded with one of
    the greatest pipes ever made by man. You, Sir, are the Bach of Briar!`,
    from: `L.S. Lawrence,
    Founder-President
    Pipe Smoker’s Hall of Fame
    Galveston Indiana
    1983`,
  },
  {
    message: `Dear Andrew,
    I cherish the pipe you made for me twelve years ago. Everyone, even non-pipe people, admires them when I smoke them. and the pipe-people admire them most of all. If you did those in your infancy, you must be the Rembrandt of pipemakers now! I have come to know quite a few pipemakers over the years, but your pipes, both beautiful and eminently functional are of exceptional quality.
    I admire your work and I bless your hands.`,
    from: `Dr. (Rabbi) Murray Goldman
    Valley Forge, Pennsylvania
    1982`,
  },
  {
    message: `Each one is a masterpiece of design – not just a pipe – a piece of sculpture.`,
    from: `The First New England Catalogue
    The Pequot Press,
    Chester, Connecticut
    1976`,
  },
  {
    message: `Dear Andrew Marks,
    The pipe is marvelous. Everyone admires it. It smokes like a charm.
    Best Regards, Anthony Shine`,
    from: `Architectes et Urbanistes Consells
    Montreal, P.Q., Canada
    1975`,
  },
  {
    message: `Dear Mr. Marks,
    Thank you for the extremely interesting pipes you have made. I am a great pipe smoker, and I admire your originality.`,
    from: `Sincerely Yours,
    Norman Rockwell
    Stockbridge, Massachusetts
    1972`,
  },
];

const Accolade: React.FC<AccoladeProps> = ({ message, from }) => (
  <div className="accolade">
    <p className="message">{message}</p>
    <p className="from">{from}</p>
  </div>
);

export const AccoladesPage: React.FC = () => (
  <div className="page-container">
    <SiteHeader />
    <div className="narrow-container">
      <h2 className="page-title">Accolades</h2>
      <div className="double-spaced">
        {accolades.map((props) => (
          <Accolade {...props} />
        ))}
      </div>
    </div>
  </div>
);
