import React from "react";
import { SiteHeader } from "./SiteHeader";
import { SRLWrapper } from "simple-react-lightbox";
import Gallery from "react-photo-gallery";
import "./HomePage.css";

const getSrc = (src: string) => `${process.env.PUBLIC_URL}${src}`;
const getPipeSrc = (filename: string) =>
  getSrc(`/images/pipe-photos/${filename}`);
const photos = [
  { src: getPipeSrc("121.jpg"), width: 4, height: 3, alt: "101" },
  { src: getPipeSrc("122.jpg"), width: 4, height: 3, alt: "102" },
  { src: getPipeSrc("123.jpg"), width: 4, height: 3, alt: "103" },
  { src: getPipeSrc("124.jpg"), width: 4, height: 3, alt: "104" },
  { src: getPipeSrc("126.jpg"), width: 4, height: 3, alt: "105" },
  { src: getPipeSrc("129.jpg"), width: 4, height: 3, alt: "106" },
  { src: getPipeSrc("130.jpg"), width: 4, height: 3, alt: "107" },
  { src: getPipeSrc("131.jpg"), width: 4, height: 3, alt: "108" },
  { src: getPipeSrc("132.jpg"), width: 4, height: 3, alt: "109" },
  { src: getPipeSrc("125.jpg"), width: 4, height: 3, alt: "110" },
  { src: getPipeSrc("127.jpg"), width: 4, height: 3, alt: "111" },
  { src: getPipeSrc("128.jpg"), width: 4, height: 3, alt: "112" },
  { src: getPipeSrc("133.jpg"), width: 4, height: 3, alt: "113" },
  { src: getPipeSrc("134.jpg"), width: 4, height: 3, alt: "114" },
  { src: getPipeSrc("135.jpg"), width: 4, height: 3, alt: "115" },
  { src: getPipeSrc("136.jpg"), width: 4, height: 3, alt: "116" },
  { src: getPipeSrc("137.jpg"), width: 4, height: 3, alt: "117" },
  { src: getPipeSrc("138.jpg"), width: 4, height: 3, alt: "118" },
  { src: getPipeSrc("139.jpg"), width: 4, height: 3, alt: "119" },
  { src: getPipeSrc("140.jpg"), width: 4, height: 3, alt: "120" },
  { src: getPipeSrc("141.jpg"), width: 4, height: 3, alt: "121" },
  { src: getPipeSrc("142.jpg"), width: 4, height: 3, alt: "122" },
  { src: getPipeSrc("144.jpg"), width: 4, height: 3, alt: "123" },
  { src: getPipeSrc("145.jpg"), width: 4, height: 3, alt: "124" },
  { src: getPipeSrc("146.jpg"), width: 4, height: 3, alt: "125" },
  { src: getPipeSrc("147.jpg"), width: 4, height: 3, alt: "126" },
  { src: getPipeSrc("120.jpg"), width: 4, height: 3, alt: "127" },
  { src: getPipeSrc("100.jpg"), width: 4, height: 3, alt: "128" },
  { src: getPipeSrc("101.jpg"), width: 4, height: 3, alt: "129" },
  { src: getPipeSrc("102.jpg"), width: 4, height: 3, alt: "130" },
  { src: getPipeSrc("103.jpg"), width: 4, height: 3, alt: "131" },
  { src: getPipeSrc("104.jpg"), width: 4, height: 3, alt: "132" },
  { src: getPipeSrc("105.jpg"), width: 4, height: 3, alt: "133" },
  { src: getPipeSrc("106.jpg"), width: 4, height: 3, alt: "134" },
  { src: getPipeSrc("107.jpg"), width: 4, height: 3, alt: "135" },
  { src: getPipeSrc("108.jpg"), width: 4, height: 3, alt: "136" },
  { src: getPipeSrc("109.jpg"), width: 4, height: 3, alt: "137" },
  { src: getPipeSrc("110.jpg"), width: 4, height: 3, alt: "138" },
  { src: getPipeSrc("111.jpg"), width: 4, height: 3, alt: "139" },
  { src: getPipeSrc("112.jpg"), width: 4, height: 3, alt: "140" },
  { src: getPipeSrc("113.jpg"), width: 4, height: 3, alt: "141" },
  { src: getPipeSrc("114.jpg"), width: 4, height: 3, alt: "142" },
  { src: getPipeSrc("115.jpg"), width: 4, height: 3, alt: "143" },
  { src: getPipeSrc("116.jpg"), width: 4, height: 3, alt: "144" },
  { src: getPipeSrc("117.jpg"), width: 4, height: 3, alt: "145" },
  { src: getPipeSrc("118.jpg"), width: 4, height: 3, alt: "146" },
  { src: getPipeSrc("119.jpg"), width: 4, height: 3, alt: "147" },
  { src: getPipeSrc("148.jpg"), width: 4, height: 3, alt: "148" },
  { src: getPipeSrc("149.jpg"), width: 4, height: 3, alt: "149" },
  { src: getPipeSrc("150.jpg"), width: 4, height: 3, alt: "150" },
  { src: getPipeSrc("151.jpg"), width: 4, height: 3, alt: "151" },
  { src: getPipeSrc("152.jpg"), width: 4, height: 3, alt: "152" },
  { src: getPipeSrc("153.jpg"), width: 4, height: 3, alt: "153" },
  { src: getPipeSrc("154.jpg"), width: 4, height: 3, alt: "154" },
  { src: getPipeSrc("155.jpg"), width: 4, height: 3, alt: "155" },
  { src: getPipeSrc("156.jpg"), width: 4, height: 3, alt: "156" },
  { src: getPipeSrc("157.jpg"), width: 4, height: 3, alt: "157" },
  { src: getPipeSrc("158.jpg"), width: 4, height: 3, alt: "158" },
  { src: getPipeSrc("159.jpg"), width: 4, height: 3, alt: "159" },
  { src: getPipeSrc("160.jpg"), width: 4, height: 3, alt: "160" },
  { src: getPipeSrc("161.jpg"), width: 4, height: 3, alt: "161" },
  { src: getPipeSrc("162.jpg"), width: 4, height: 3, alt: "162" },
  { src: getPipeSrc("163.jpg"), width: 4, height: 3, alt: "163" },
  { src: getPipeSrc("164.jpg"), width: 4, height: 3, alt: "164" },
  { src: getPipeSrc("165.jpg"), width: 4, height: 3, alt: "165" },
  { src: getPipeSrc("166.jpg"), width: 4, height: 3, alt: "166" },
  { src: getPipeSrc("167.jpg"), width: 4, height: 3, alt: "167" },
  { src: getPipeSrc("168.jpg"), width: 4, height: 3, alt: "168" },
  { src: getPipeSrc("169.jpg"), width: 4, height: 3, alt: "169" },
  { src: getPipeSrc("170.jpg"), width: 4, height: 3, alt: "170" },
  { src: getPipeSrc("171.jpg"), width: 4, height: 3, alt: "171" },
  { src: getPipeSrc("172.jpg"), width: 4, height: 3, alt: "172" },
  { src: getPipeSrc("173.jpg"), width: 4, height: 3, alt: "173" },
  { src: getPipeSrc("174.jpg"), width: 4, height: 3, alt: "174" },
  { src: getPipeSrc("175.jpg"), width: 4, height: 3, alt: "175" },
  { src: getPipeSrc("176.jpg"), width: 4, height: 3, alt: "176" },
  { src: getPipeSrc("177.jpg"), width: 4, height: 3, alt: "177" },
  { src: getPipeSrc("178.jpg"), width: 4, height: 3, alt: "178" },
  { src: getPipeSrc("179.jpg"), width: 4, height: 3, alt: "179" },
  { src: getPipeSrc("200.jpg"), width: 4, height: 3, alt: "180" },
  { src: getPipeSrc("201.jpg"), width: 4, height: 3, alt: "181" },
  { src: getPipeSrc("202.jpg"), width: 4, height: 3, alt: "182" },
  { src: getPipeSrc("203.jpg"), width: 4, height: 3, alt: "183" },
  { src: getPipeSrc("204.jpg"), width: 4, height: 3, alt: "184" },
  { src: getPipeSrc("205.jpg"), width: 4, height: 3, alt: "185" },
  { src: getPipeSrc("206.jpg"), width: 4, height: 3, alt: "186" },
  { src: getPipeSrc("207.jpg"), width: 4, height: 3, alt: "187" },
  { src: getPipeSrc("208.jpg"), width: 4, height: 3, alt: "188" },
  { src: getPipeSrc("209.jpg"), width: 4, height: 3, alt: "189" },
  { src: getPipeSrc("210.jpg"), width: 4, height: 3, alt: "190" },
  { src: getPipeSrc("211.jpg"), width: 4, height: 3, alt: "191" },
  { src: getPipeSrc("212.jpg"), width: 4, height: 3, alt: "192" },
  { src: getPipeSrc("213.jpg"), width: 4, height: 3, alt: "193" },
  { src: getPipeSrc("214.jpg"), width: 4, height: 3, alt: "194" },
  { src: getPipeSrc("215.jpg"), width: 4, height: 3, alt: "195" },
  { src: getPipeSrc("216.jpg"), width: 4, height: 3, alt: "196" },
  { src: getPipeSrc("217.jpg"), width: 4, height: 3, alt: "197" },
  { src: getPipeSrc("218.jpg"), width: 4, height: 3, alt: "198" },
  { src: getPipeSrc("219.jpg"), width: 4, height: 3, alt: "199" },
  { src: getPipeSrc("220.jpg"), width: 4, height: 3, alt: "200" },
  { src: getPipeSrc("221.jpg"), width: 4, height: 3, alt: "201" },
  { src: getPipeSrc("222.jpg"), width: 4, height: 3, alt: "202" },
  { src: getPipeSrc("224.jpg"), width: 4, height: 3, alt: "203" },
  { src: getPipeSrc("300.jpg"), width: 4, height: 3, alt: "204" },
  { src: getPipeSrc("301.jpg"), width: 4, height: 3, alt: "205" },
  { src: getPipeSrc("302.jpg"), width: 3, height: 4, alt: "206" },
  { src: getPipeSrc("303.jpg"), width: 3, height: 4, alt: "207" },
  { src: getPipeSrc("304.jpg"), width: 3, height: 4, alt: "208" },
  { src: getPipeSrc("305.jpg"), width: 4, height: 3, alt: "209" },
  { src: getPipeSrc("306.jpg"), width: 4, height: 3, alt: "210" },
  { src: getPipeSrc("307.jpg"), width: 4, height: 3, alt: "211" },
  { src: getPipeSrc("308.jpg"), width: 730, height: 487, alt: "212" },
  { src: getPipeSrc("309.jpg"), width: 4, height: 3, alt: "213" },
  { src: getPipeSrc("310.jpg"), width: 4, height: 3, alt: "214" },
  { src: getPipeSrc("311.jpg"), width: 4, height: 3, alt: "215" },
  { src: getPipeSrc("312.jpg"), width: 3, height: 4, alt: "216" },
  { src: getPipeSrc("313.jpg"), width: 4, height: 3, alt: "217" },
  { src: getPipeSrc("314.jpg"), width: 4, height: 3, alt: "218" },
];

// https://neptunian.github.io/react-photo-gallery/

const galleryMargin = 20;
export const HomePage: React.FC = () => (
  <div className="page-container">
    <SiteHeader />
    <div
      style={{
        margin: "auto",
        width: `calc(var(--full-content-width) + ${2 * galleryMargin}px)`,
      }}
    >
      <SRLWrapper>
        <Gallery photos={photos} margin={galleryMargin} targetRowHeight={300} />
      </SRLWrapper>
    </div>
  </div>
);
